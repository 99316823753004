import "./homePage.css";
import Jersey from "../Images/Jersey-Temporary.png";
import Face from "../Images/FaceChange-Player-Isolated.png";
import LeftHeadText from "../Images/WaliaTagline-Isolated.png";
import RightHeadText from "../Images/WebsiteHeadline-Isolated.png";
import Logo from "../Images/Sponsorship-Logo.png";
import BottomText from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import arrowDown from "../Images/arrowDown.gif";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div className="home">
      <div className="homeWrapper">
        <div className="homeLogoContainer">
          <img className="homeLogo" src={Logo} />
          <div>
            <img className="arrowDown" src={arrowDown} />
          </div>
        </div>
        <div className="containerWrapper">
          <div className="container1">
            <img className="homeJersey" src={Jersey} />
            <img className="homeJerseyText" src={LeftHeadText} />
            <button className="homeButton1">
              <Link to="/changeJersey" className="changeJerseyLink">
                ብሄራዊ ቡድኑን ይምሰሉ!
              </Link>
            </button>
          </div>
          <div className="container2">
            <img className="homeFace" src={Face} />
            <img className="homeFaceText" src={RightHeadText} />
            <button className="homeButton2">
              <Link to="/changeFace" className="changeJerseyLink">
                ዋልያዎቹን ይምሰሉ
              </Link>
            </button>
          </div>
        </div>
        <div className="bottomTextContainer">
          <img className="bottomText" src={BottomText} />
        </div>
      </div>
    </div>
  );
}
