import React, { useRef } from "react";
import domtoimage from "dom-to-image";
import Confetti from "react-confetti";
import "./changedFace.css";
import HeadTextImg from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import FaceImg from "../Images/FinalFace.png";
import DownloadIcon1 from "../Images/Download.png";
import DownloadIcon2 from "../Images/Twitter.png";
import DownloadIcon3 from "../Images/Telegram.png";
import DownloadIcon4 from "../Images/Facebook.png";
import WaliaLogo from "../Images/Walia-Logo.png";
import FootballLogo from "../Images/EFF-Logo.png";

export default function ChangedFace() {
  let id = new URLSearchParams(window.location.search).get("id");
  if (id == null || localStorage.getItem(id) == null) {
    window.location.href = "/changeFace";
  }
  const container = useRef(null);
  async function exportToPng(dom) {
    try {
      const dataUrl = await domtoimage.toPng(dom);
      return dataUrl;
    } catch (error) {
      console.error("oops, something went wrong!", error);
    }
    return null;
  }

  async function dowloadImage(e) {
    e.preventDefault();
    const url = await exportToPng(container.current);
    if (!url) return;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    // the filename of downloaded file
    a.download = "Walia-Face_change.png";
    document.body.appendChild(a);
    a.click();
    document.removeChild(a);
  }
  return (
    <div className="changedFaceContainer">
      <Confetti
        className="confetti"
        friction="1"
        recycle={false}
        numberOfPieces="7000"
        tweenDuration="10000"
      />
      <div className="changedFace">
        <img src={HeadTextImg} alt="" className="changedFaceHeadText" />
        <div className="changedFaceWrapper1">
          <div ref={container} className="changedFaceImgContainer">
            <img
              className="cropedFaceImg"
              src={localStorage.getItem(id)}
              alt=""
            />
            <img className="cropedFaceImg1" src={FaceImg} alt="" />
          </div>
        </div>
      </div>
      <div className="changedFace2">
        <div>
          <img src={WaliaLogo} alt="" className="changedFaceLogoIcons" />
        </div>
        <div className="changedFaceHeadText2">
          <div className="downloadText">
            <span>ለወዳጅዎ ያጋሩ</span>
          </div>

          <div className="iconsContainer">
            <a onClick={dowloadImage}>
              <img className="downloadIcons" src={DownloadIcon1} />
            </a>
            <img className="downloadIcons1" src={DownloadIcon2} />
            <img className="downloadIcons1" src={DownloadIcon3} />
            <img className="downloadIcons1" src={DownloadIcon4} />
          </div>
        </div>
        <div>
          <img src={FootballLogo} alt="" className="changedFaceLogoIcons" />
        </div>
      </div>
    </div>
  );
}
