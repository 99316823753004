import Home from "./pages/Home";
import ChangeJersey from "./pages/ChangeJersey";
import ChangedJersey from "./pages/ChangedJersey";
import ChangeFace from "./pages/ChangeFace";
import ChangedFace from "./pages/ChangedFace";
import Jersey from "./pages/Jersey";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/changeJersey">
            <ChangeJersey />
          </Route>
          <Route exact path="/changedJersey">
            <ChangedJersey />
          </Route>
          <Route exact path="/changeFace">
            <ChangeFace />
          </Route>
          <Route exact path="/changedFace">
            <ChangedFace />
          </Route>
          <Route exact path="/jersey">
            <Jersey />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
