import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./jersey.css";
import HeadTextImg from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import soccerJersey from "../Images/soccer.png";
import JerseyImg from "../Images/newJerseyImg.jpeg";

export default function Jersey() {
  let id = new URLSearchParams(window.location.search).get("id");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  let [status, setStatus] = useState("");
  let [jerseyName, setJerseyName] = useState("");
  let [jerseyNumber, setJerseyNumber] = useState("");

  fetch(
    `https://afcon.xhahu.com/api/?method=getJersey&id=${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      setStatus(result.status);
      setJerseyName(result.jerseyName);
      setJerseyNumber(result.jerseyNo);
    })
    .catch((error) => console.log("error", error));

  if (id == null || status == "error") {
    window.location.href = "/changeJersey";
  }
  return (
    <div>
      <Helmet>
        <title>Make your own jersey</title>
      </Helmet>
      <div className="changedFullContainer">
        <div className="changedJersey">
          <div className="changedJerseyWrapper1">
            <div className="changedJerseyHeadTextContainer">
              <img src={HeadTextImg} alt="" className="changedJerseyHeadText" />
            </div>
            <div className="changedJerseyImgContainer">
              <img className="changedJerseyImg" src={JerseyImg} alt="" />
              <h1 className="jerseyName">{jerseyName}</h1>
              <h1 className="jerseyNumber">{jerseyNumber}</h1>
            </div>
          </div>
        </div>
        <div className="changedJersey2">
          <div className="changedJerseyHeadText2">
            <div>
              <span>
                የ <span className="shareText">{jerseyName}</span> ማልያ
              </span>
            </div>
            <div>
              <button id="redirectButton">
                <img className="soccerJersey" src={soccerJersey} />
                <Link to="/changeJersey" className="buttonText">
                  የራስዎን ማልያ ይስሩ
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
