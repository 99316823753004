import React, { useRef } from "react";
import domtoimage from "dom-to-image";
import Confetti from "react-confetti";
import "./changedJersey.css";
import HeadTextImg from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import JerseyImg from "../Images/newJerseyImg.jpeg";
import DownloadIcon1 from "../Images/Download.png";
import DownloadIcon2 from "../Images/Twitter.png";
import DownloadIcon4 from "../Images/Facebook.png";

export default function ChangedJersey() {
  let id = new URLSearchParams(window.location.search).get("id");

  if (id == null || localStorage.getItem(id) == null) {
    window.location.href = "/changeJersey";
  }

  const jersyInfo = JSON.parse(localStorage.getItem(id));
  console.log(jersyInfo);
  let { fName, lName } = jersyInfo;

  console.log(fName);
  console.log(lName);
  console.log(id);

  // proccess edited picture
  const container = useRef(null);
  async function exportToPng(domNode) {
    let scale = 1.7;
    try {
      const dataUrl = await domtoimage.toPng(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      });
      return dataUrl;
    } catch (error) {
      console.error("oops, something went wrong!", error);
    }
    return null;
  }

  async function dowloadImage(e) {
    e.preventDefault();
    const url = await exportToPng(container.current);
    if (!url) return;

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = "Walia-Jersey.png";
    document.body.appendChild(a);
    a.click();

    document.removeChild(a);
  }

  let customLink = "https://share.walians.et/";
  let shareMessage = "ከእናንተ ጋር ነን! #TeamEthiopia #Walians";
  let shareText = encodeURIComponent(shareMessage);

  return (
    <div className="changedFullContainer">
      <Confetti
        className="confetti"
        friction="1.01"
        recycle={false}
        numberOfPieces="600"
        tweenDuration="10000"
      />
      <div className="changedJersey">
        <div className="changedJerseyWrapper1">
          <div className="changedJerseyHeadTextContainer">
            <img src={HeadTextImg} alt="" className="changedJerseyHeadText" />
          </div>
          <div ref={container} className="changedJerseyImgContainer">
            <img className="changedJerseyImg" src={JerseyImg} alt="" />
            <h1 className="jerseyName">{fName}</h1>
            <h1 className="jerseyNumber">{lName}</h1>
          </div>
        </div>
      </div>
      <div className="changedJersey2">
        <div className="changedJerseyHeadText2">
          <div>
            <span>ለወዳጅዎ ያጋሩ</span>
          </div>
          <div>
            <a onClick={dowloadImage}>
              <img className="downloadIcons" src={DownloadIcon1} />
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/intent/tweet?url=${customLink}?id=${id}&text=${shareText}`}
            >
              <img className="downloadIcons" src={DownloadIcon2} />
            </a>
            <a
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=${customLink}?id=${id}`}
            >
              <img className="downloadIcons" src={DownloadIcon4} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
