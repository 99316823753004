import React, { useState } from "react";
import "./changeJersey.css";
import HeadTextImg from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import LogoIcon1 from "../Images/EFF-Logo.png";
import LogoIcon2 from "../Images/Walia-Logo.png";

function ChangeJersey() {
  // generate random string
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  let [fullName, setFullName] = useState({ fName: "", lName: "" });

  function generateJerseyImg() {
    let randID = makeid(18);
    localStorage.setItem(randID, JSON.stringify(fullName));

    // send image data to server

    var formdata = new FormData();
    formdata.append("id", randID);
    formdata.append("name", fullName.fName);
    formdata.append("number", fullName.lName);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://afcon.xhahu.com/api/?method=saveJersey", requestOptions)
      .then((response) => response.text())
      .then((result) => (window.location.href = `/changedJersey?id=${randID}`))
      .catch((error) => console.log("error", error));

    // window.location.href = `/changedJersey?id=${randID}`;
    // console.log(randID);
    // console.log(fullName.fName);
    // console.log(fullName.lName);
  }

  function updateChange(event) {
    let { value, name } = event.target;
    console.log(value, name);
    setFullName(function previousValue(prevValue) {
      if (name === "fName") {
        return { fName: value, lName: prevValue.lName };
      } else if (name === "lName") {
        if (+value <= 99)
          return {
            fName: prevValue.fName,
            lName: value,
          };
      }
      return prevValue;
    });

    // forbid white space from input field

    var field = document.querySelector('[name="fName"]');
    var field2 = document.querySelector('[name="lName"]');
    field.addEventListener("keypress", function (event) {
      var key = event.keyCode;
      if (key === 32) {
        event.preventDefault();
      }
    });

    field2.addEventListener("keypress", function (event) {
      var key = event.keyCode;
      if (key === 32) {
        event.preventDefault();
      }
    });
  }

  localStorage.clear();

  return (
    <div className="fullContainer">
      <div className="changeJersey2">
        <div></div>
        <div className="changeJerseyHeadTextContainer">
          <img src={HeadTextImg} alt="" className="changeJerseyHeadText" />
        </div>
        <div className="changeJerseyHeadText2">በስምዎ የዋልያዎችን ማልያ ይስሩ</div>
        <div className="inputsAndButton">
          <span className="changeJerseyLabels">ስም</span>
          <input
            onChange={updateChange}
            name="fName"
            value={fullName.fName}
            placeholder="ስምዎን ያስገቡ"
            maxLength="10"
            required
          />
          <span className="changeJerseyLabels">ማልያ ቁጥር</span>
          <input
            type="number"
            onChange={updateChange}
            name="lName"
            value={fullName.lName}
            placeholder="የማልያ ቁጥሮን ያስገቡ"
            required
          />
        </div>
        <div className="changeJerseyWrapper2">
          <div className="ChangeJerseyLeftIcon">
            <img src={LogoIcon2} alt="" className="logoIcon" />
          </div>
          <div>
            <button onClick={generateJerseyImg} className="changeJerseyButton">
              ማልያ ስራ
            </button>
          </div>
          <div className="ChangeJerseyRightIcon">
            <img src={LogoIcon1} alt="" className="logoIcon" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChangeJersey;
