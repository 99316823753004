import "./changeFace.css";
import HeadTextImg from "../Images/WaliaTagline-WebsiteHeadline-Stacked.png";
import WaliaLogo from "../Images/Walia-Logo.png";
import FootballLogo from "../Images/EFF-Logo.png";
import instagram from "../Images/instagram.png";
import facebook1 from "../Images/facebook1.png";
import InstaAndFacebook from "../Images/instaAndFacebook.png";

export default function ChangeFace() {
  return (
    <div className="container">
      <div className="wrapper1">
        <div className="changedHeadText">
          <img className="headText" src={HeadTextImg} />
        </div>
        <div className="facebookAndInsta">
          <img className="facebookAndInsta" src={InstaAndFacebook} />
        </div>
      </div>

      <div className="wrapper2">
        <div className="bottomWrapper">
          <div>
            <img className="logoIcons" src={WaliaLogo} />
          </div>
          <div className="buttonContainer">
            <a
              href="https://www.instagram.com/ar/348332843390786/"
              className="changeFaceButton"
              target="_blank"
            >
              <div>
                <img className="buttonIcons" src={instagram} />
              </div>
              በ <span className="insAndFac">Instagram</span> ይቀይሩ
            </a>
            <a
              className="changeFaceButton"
              target="_blank"
              href="https://facebook.com/fbcameraeffects/tryit/348332843390786/"
            >
              <div>
                <img className="buttonIcons" src={facebook1} />
              </div>
              በ <span className="insAndFac"> Facebook </span> ይቀይሩ
            </a>
          </div>
          <div>
            <img className="logoIcons" src={FootballLogo} />
          </div>
        </div>
      </div>
    </div>
  );
}
