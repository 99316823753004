import "./home.css";
import HomePage from "../components/HomePage";

export default function Home() {
  return (
    <div>
      <HomePage />
    </div>
  );
}
